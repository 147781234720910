











































import { Component, Prop, Vue } from 'vue-property-decorator'
import { GiftCard, Sku } from '@/models'
import { Form } from 'element-ui'
import DirectUpload from '@/components/DirectUpload/index.vue'

@Component({
  name: 'CreateSkuDialog',
  components: {
    DirectUpload
  }
})
export default class extends Vue {
  @Prop() public value!: boolean
  @Prop() public giftCard!: GiftCard
  @Prop() public dialogStatus!: string
  @Prop() public dialogData!: Sku

  private tempData = new Sku({ giftCard: new GiftCard({ id: this.giftCard.id }), faceValueFree: false })
  private createOrUpdateLoading = false
  private textMap = {
    update: 'Edit',
    create: 'Create'
  }

  created() {
    if (this.dialogStatus === 'update') this.tempData = this.dialogData
    else this.tempData.giftCard.isPersisted = true
    this.$nextTick(() => {
      (this.$refs.dataForm as Form).clearValidate()
    })
  }

  resetFaceValue(reset: boolean) {
    if (!reset) {
      this.tempData.faceValueMax = undefined
      this.tempData.faceValueMin = undefined
    } else this.tempData.faceValue = null
  }

  private createOrUpdate() {
    (this.$refs.dataForm as Form).validate(async(valid) => {
      this.createOrUpdateLoading = true
      if (valid) {
        const data = this.tempData

        if (!this.tempData?.faceValueFree && !this.tempData?.centsEnabled && this.tempData?.faceValue !== null && this.tempData?.faceValue % 1 !== 0) {
          this.$notify({
            title: 'Erreur de validation',
            message: 'Les décimales ne sont pas autorisées lorsque les centimes sont désactivés',
            type: 'error',
            duration: 2000
          })
          this.createOrUpdateLoading = false
          return
        }

        try {
          await data.save({ with: 'giftCard.id' })
          if (this.dialogStatus === 'create') {
            this.$emit('pushTable', data)
            this.$notify({
              title: 'Sku Created',
              message: 'Sku successfully created',
              type: 'success',
              duration: 2000
            })
          } else {
            this.$emit('updateTable', data)
            this.$notify({
              title: 'Sku Updated',
              message: 'Sku successfully updated',
              type: 'success',
              duration: 2000
            })
          }
        } catch (err) {
          this.createOrUpdateLoading = false
        }
      }
      this.createOrUpdateLoading = false
      this.$emit('input', false)
    })
  }

  private uploadCallback(file: any) {
    this.tempData.cardImage = file.blob.signed_id
  }
}
