


























































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { GiftCard } from '@/models'
import VueTrix from 'vue-trix'

@Component({
  name: 'GiftCardSettings',
  components: {
    VueTrix
  }
})
export default class extends Vue {
  @Prop() private giftCard!: GiftCard

  hide: any = {
    table: true
  }

  private readonly maxLength = 2750;
  private showCharLimitWarning = false;

  set howToUseOnline(value: string) {
    this.$emit('howToUseOnline', value)
  }

  get howToUseOnline(): string {
    return this.giftCard.howToUseOnline
  }

  set howToUseOffline(value: string) {
    this.$emit('howToUseOffline', value)
  }

  get howToUseOffline(): string {
    return this.giftCard.howToUseOffline
  }

  set termOfUse(value: string) {
    this.$emit('termOfUse', value)
  }

  get termOfUse(): string {
    return this.giftCard.termOfUse || ''
  }

  limitCharacters(value: string) {
    const currentValue = value || '';
    if (currentValue.length > this.maxLength) {
      this.$nextTick(() => {
        this.termOfUse = currentValue.substring(0, this.maxLength);
      });
      this.showCharLimitWarning = true;
      this.$emit('termOfUseError', true);
    } else {
      this.showCharLimitWarning = false;
      this.$emit('termOfUseError', false);
    }
  }
}
