
































































































import { Component, Vue } from 'vue-property-decorator'
import DirectUpload from '@/components/DirectUpload/index.vue'
import { IValidationError } from 'spraypaint/lib-esm/validation-errors'
import { GiftCard } from '@/models'
import VueTrix from 'vue-trix'

@Component({
  components: {
    GiftCardNetworkSettings: () => import('./components/GiftCardNetworkSettings.vue'),
    GiftCardUsageRules: () => import('./components/GiftCardUsageRules.vue'),
    GiftCardSettings: () => import('./components/GiftCardSettings.vue'),
    GiftCardVisualSettings: () => import('./components/GiftCardVisualSettings.vue'),
    SkuTable: () => import('./components/SkuTable.vue'),
    DirectUpload,
    VueTrix
  }
})
export default class extends Vue {
  private activeTab = '1'
  public giftCard = new GiftCard({ faceValueAvailable: [], pdfTemplate: 'WalletCardPdf' })
  public brandLoading = true
  private selectedPdfTemplate: any = null
  private pdfTemplateList = [
    {
      label: 'Be Wallet',
      value: 'BeWalletCardPdf'
    },
    {
      label: 'Bio-Tiful',
      value: 'BiotifulCardPdf'
    },
    {
      label: 'Casino',
      value: 'CasinoCardPdf'
    },
    {
      label: 'Colruyt Let\'s Eat',
      value: 'ColruytCardPdf'
    },
    {
      label: 'Systeme U',
      value: 'SystemeUCardPdf'
    },
    {
      label: 'Wallet',
      value: 'WalletCardPdf'
    },
    {
      label: 'Yves Rocher',
      value: 'YvesRocherCardPdf'
    }
  ]
  private hasTermOfUseError = false;

  private updatePdfTemplate(template: any) {
    this.giftCard.pdfTemplate = template.value
  }

  private async getBrandById() {
    const { data } = await GiftCard.selectExtra(['logoBlob', 'logoSquareBlob']).find(this.$route.params.id)
    this.giftCard = data
    this.selectedPdfTemplate = this.pdfTemplateList.find(t => t.value === this.giftCard.pdfTemplate) || null
    this.brandLoading = false
  }

  handleTermOfUseError(hasError: boolean) {
    this.hasTermOfUseError = hasError;
  }

  async updateData() {
    if (this.hasTermOfUseError) {
      this.$notify({
        title: 'Erreur',
        message: 'Les conditions d\'utilisation dépassent la limite de 2750 caractères',
        type: 'error',
        duration: 5000
      });
      return;
    }
    await this.giftCard.save()

    if (Object.keys(this.giftCard.errors).length > 0) {
      this.printErrors(this.giftCard.errors)
    } else {
      this.$notify({
        title: 'GiftCard Updated',
        message: 'GiftCard successfully updated',
        type: 'success',
        duration: 2000
      })
    }
  }

  async createData() {
    if (this.hasTermOfUseError) {
      this.$notify({
        title: 'Erreur',
        message: 'Les conditions d\'utilisation dépassent la limite de 2750 caractères',
        type: 'error',
        duration: 5000
      });
      return;
    }
    await this.giftCard.save({})

    if (Object.keys(this.giftCard.errors).length > 0) {
      this.printErrors(this.giftCard.errors)
    } else {
      this.$notify({
        title: 'GiftCard created',
        message: 'GiftCard successfully created',
        type: 'success',
        duration: 2000
      })
    }
  }

  private printErrors(errors: any) {
    const e: (IValidationError<GiftCard> | undefined)[] = Object.values(errors)
    for (const error of e) {
      if (error) {
        this.$notify({
          title: error.title,
          message: error.fullMessage,
          type: 'error',
          duration: 5000
        })
      }
    }
  }

  private uploadCallback(file: any) {
    this.giftCard.logo = file.blob.signed_id
  }

  created() {
    if (this.$route?.params?.id) {
      this.getBrandById()
    } else {
      this.brandLoading = false
      this.selectedPdfTemplate = this.pdfTemplateList.find(t => t.value === 'WalletCardPdf')
    }
  }

  get isCreate() {
    return this.$route.name === 'GiftCardCreate'
  }
}
